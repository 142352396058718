// dash-sidebar-button styles
@import "../../../styles/_variables.scss";

.dash-sidebar-button {
  font-size: $text-kilo;
  background: #4a90e2;
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  display: block;
  font-weight: 700;
  margin-top: 1rem;
  outline: none;
  padding: 1rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.dash-sidebar-button--disabled {
  @extend .dash-sidebar-button;
  background: #bebebe;
  color: #fff;
  &:active,
  &:focus {
    background: #eeeeee !important;
  }
}
