// columns styles
[class*="pp-col"] {
  &:not(:first-child) {
    margin-top: 2rem;
    @include respond-to(small) {
      margin-top: 0;
    }
  }
}
.pp-col__1of1 {
  width: 100%;
}
.pp-col__1of2 {
  @include respond-to(small) {
    width: 50%;
  }
}
.pp-col__1of3 {
  @include respond-to(small) {
    width: 33.33%;
  }
}
.pp-col__2of3 {
  @include respond-to(small) {
    width: 66.66%;
  }
}
.pp-col__1of4 {
  @include respond-to(small) {
    width: 25%;
  }
}
.pp-col__3of4 {
  @include respond-to(small) {
    width: 75%;
  }
}

// With GUtters

@include respond-to(small) {
  .pp-row--w-gutters {
    [class*="pp-col"] {
      margin: 0 0.5rem;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .pp-col__1of2 {
      @include respond-to(small) {
        width: calc(50% - 0.5rem);
      }
    }
    .pp-col__1of3 {
      @include respond-to(small) {
        width: calc(33.33% - 0.75rem);
      }
    }
    .pp-col__2of3 {
      @include respond-to(small) {
        width: calc(66.66% - 0.25rem);
      }
    }
    .pp-col__1of4 {
      @include respond-to(small) {
        width: calc(25% - 0.5rem);
      }
    }
    .pp-col__3of4 {
      @include respond-to(small) {
        width: calc(75% - 1rem);
      }
    }
  }
}
// With vertical rule
@include respond-to(small) {
  .pp-row--w-rule {
    [class*="pp-col"] {
      padding-right: 1rem;
      margin: 0 0.5rem;
      border-right: solid 1px #eee;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
    }
  }
}
