// dash-side styles

.dash-side {
  background: white;
  height: 88vh;
  margin-right: 4px;
  order: 1;
  overflow-y: auto;
  padding-bottom: 1rem;
  width: 400px;
  position: relative;
  padding: 20px;
  @media print {
    display: none;
  }
}

.dash-side-cntnr {
  margin-top: 10px;
  label {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 125%;
  }
}
