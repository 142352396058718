// Font Stack

@import './_variables.scss';

// Base Typography
html,
body {
  font-size: $font-size-mobile;
  font-family: $font-default;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include respond-to(small) {
    font-size: $font-size-tablet;
  }

  @include respond-to(medium) {
  }

  @include respond-to(large) {
    font-size: $font-size-desktop;
  }
}
// Heading Resets
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $text-default;
  font-size: $text-weight-default;
  // line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  line-height: 1.85;
  margin-top: 0;
  margin-bottom: 0;
}
p + p {
  margin-top: 1.25em;
}
// Text Helpers
.text-italic {
  font-style: italic;
}
sup {
  font-size: 0.3em;
  top: -1.5em;
  right: -0.25em;
  margin-right: 0.5em;
}

.pp-typography {
  p {
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
  }
  h1 {
    font-size: $text-giga;
  }
  h2 {
    font-size: $text-mega;
    font-weight: 600;
  }
  h3 {
    font-size: $text-default;
    text-decoration: underline;
  }
  h4 {
    font-size: $text-default;
    font-style: italic;
  }
  ul {
  }
  li {
    margin-top: 1rem;
    line-height: 1.85;
  }
}
.inline-icon {
  vertical-align: middle;
  height: 1em;
  margin: 0 0.25rem;
}
