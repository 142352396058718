.ReactTable .rt-tbody {
    -webkit-box-flex: 99999;
    -ms-flex: 99999 1 auto;
    flex: 99999 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    padding-left: 1rem;
    width:calc(100%-1rem);
  }
  
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
  
  .ReactTable .rt-tbody .rt-tr-odd {
    max-height: fit-content
  }

  .ReactTable .rt-tr {
    max-height: fit-content
  }

  .ReactTable .rt-td {
    padding-left: 0.5rem
  }