.transfered-main_cntnr {
  padding: 0 17rem;
  display: flex;
  height: 81vh;
  flex-direction: column;
  background: #fff;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 125%;
    display: flex;
    justify-content: center;
  }

  .transfer-main_hdr {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 125%;
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
  }
}
