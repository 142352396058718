// bucket styles

.pp-bucket {
  padding: 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  @include respond-to(small) {
    padding: 4rem 0 6rem;
  }
}
