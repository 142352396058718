// dash-widget styles
@import '../../../../styles/variables';

.dash-widget {
  padding: 1rem;
  cursor: pointer;
}

.dash-widget__selected {
  @extend .dash-widget;
  margin: 6px 0;
  padding: 0.6rem 1rem;
  cursor: context-menu;
  background: #cee1f7;
  border-radius: 0px 100px 100px 0px;
}
.dash-widget__header {
  display: flex;
  & > h2 {
    margin-bottom: 0.25rem;
  }
}
.dash-widget__title {
  font-size: $text-mega;
  font-weight: 700;
  width: 100%;
  @media screen and (max-width: 1367px) {
    font-size: 1.1rem;
  }
}
.dash-widget__count {
  font-size: $text-mega;
  font-weight: 800;
  @media screen and (max-width: 1367px) {
    font-size: 1.7rem;
  }
}
