.radio {
  display: flex;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  font-size: 1rem;
  margin: 15px;
  .label {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #c8ccd4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #225cff;
      transform: scale(0);
      transition: all 0.2s ease;
      opacity: 0.08;
      pointer-events: none;
    }
  }
  &:hover .label:after {
    transform: scale(3.6);
  }
}

input[type="radio"] {
  &:checked + .label {
    border-color: #225cff;
    &:after {
      transform: scale(1);
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }
}
