// workflow-primary-btn styles

$primaryColor: #4a90e2;

@mixin button {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 27px;
  font-weight: 900;
  height: 6.5rem;
  justify-content: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  transition: 200ms;
  &__icon {
    margin-right: 1rem;
  }
  &:focus,
  &:hover {
    box-shadow: 0 0 30px 0 $primaryColor;
    outline: none;
  }
}

.primary-btn {
  @include button;
  background: $primaryColor;
  border: none;
  color: white;
  min-height: 82px;
  margin-bottom: 10px;
}
.primary-btn--disabled {
  @include button;
  background: #eee;
  border: none;
  color: black;
}
.primary-btn--ghost {
  @include button;
  background: transparent;
  border: solid 2px $primaryColor;
  color: $primaryColor;
  font-weight: 600;
  min-height: 82px;
  margin-bottom: 10px;
  &:focus,
  &:hover {
    border: solid 2px white;
  }
}
.workflow-primary-btn {
  @include button;
  background: $primaryColor;
  border: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 0 30px 0 $primaryColor;
  color: white;
  &:focus,
  &:hover {
    outline: none;
  }
}
