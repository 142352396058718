// dash-header styles

.dash-header {
  background: #1b1c1d;
  display: flex;
  align-content: center;
  align-items: center;
  height: 4.7rem;
  padding: 0 1rem;
  padding-right: 0;
  width: 100vw;
  order: 0;
  @media screen and (max-width: 1367px) {
    width: 100%;
  }
  @media print {
    display: none;
  }
}

.dash-header__left {
  display: flex;
  flex-shrink: 0;
  width: calc(300px + 1rem);
}

.dash-header__right {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
