.dropdown-cntnr {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 10px;
  label {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 125%;
    padding-left: 2px;
  }
}

select {
  margin: 2px;
  width: 100%;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  background: url(arrow-down.svg) 98%/3.5% no-repeat #f2f2f2;
}

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none; /* remove default arrow on ie10 and ie11 */
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}
