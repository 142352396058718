@import "../../../../../styles/variables.scss";


.dash-search-container {
    padding: 20px;
    border-bottom: 1px #e5e5e5 solid;
  }

.dash-search {
    position: relative;
    min-width: 300px;
    flex-shrink: 1;
    margin-right: 2rem;
    flex-shrink: 1;
    flex-grow: 1;
    margin-top: 10px;
  }

.dash-search__label {
    visibility: hidden;
    display: none;
  }

.dash-search__input {
    font-size: $text-kilo;
    width: 100%;
    border: none;
    background: #f2f2f2;
    height: 3.5rem;
    border-radius: 3px;
    padding: 1rem 1rem 1rem 4rem;
  }

.dash-search__button {
    background: none;
    border: none;
    left: 0;
    position: absolute;
    top: 0;
    height: 3.5rem;
    width: 3.5rem;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    &:after {
      background-image: url("./icon-barcode.svg");
      background-size: 30px 30px;
      display: inline-block;
      width: 30px;
      height: 30px;
      content: "";
    }
}

