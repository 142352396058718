.dashboard {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100vw;
  background: #f2f2f2;
  @media screen and (max-width: 1367px) {
    width: 100%;
  }
}
.dashboard--squeezed {
  width: calc(100% - 350px);
  transform: translateX(350px);
}

.dash-main-bttn-side {
  display: flex;
  position: "absolute";
  justify-content: "flex-end";
  padding: 0px 10px 10px 10px;
  box-shadow: 0px -4px 10px rgba(102, 102, 102, 0.25);
  position: relative;
  z-index: 10;
}
