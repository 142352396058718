// container styles

@mixin pc-container {
  // @include grid-container;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1rem;
  @include respond-to(small) {
    padding: 0 2rem;
  }
}

.pp-container,
%att-container {
  @include pc-container;
  @include respond-to(small) {
    width: 100%;
  }
  @include respond-to(medium) {
    width: 992px;
  }
  @include respond-to(large) {
    width: 1168px;
  }
}

.pp-container--centi,
%att-container--centi {
  @include pc-container;
  @include respond-to(small) {
    width: 928px;
    max-width: 100%;
  }
  @include respond-to(medium) {
    width: 928px;
    max-width: 100%;
  }
  @include respond-to(large) {
    width: 992px;
    max-width: 100%;
  }
}
.pp-container--milli {
  @include pc-container;
  @include respond-to(small) {
    width: 53rem;
    max-width: 100%;
  }
}
.pp-container--micro {
  @include pc-container;
  @include respond-to(small) {
    width: 42rem;
    max-width: 100%;
  }
}
.pp-container--nano {
  @include pc-container;
  @include respond-to(small) {
    width: 38rem;
    max-width: 100%;
  }
}
.pp-container--pico {
  @include pc-container;
  @include respond-to(small) {
    width: 18rem;
    max-width: 100%;
  }
}
