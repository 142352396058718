/*==============================
=             RESPONSIVE MIXINS            =
==============================*/

// Global Variables
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'small-max' {
    @media (max-width: $screen-sm - 1) {
      @content;
    }
  }
  @if $breakpoint == 'medium-max' {
    @media (max-width: $screen-md - 1) {
      @content;
    }
  }

  @if $breakpoint == 'large-max' {
    @media (max-width: $screen-lg - 1) {
      @content;
    }
  }

  @if $breakpoint == 'small' {
    @media (min-width: $screen-sm) {
      @content;
    }
  } @else if $breakpoint == 'medium' {
    @media (min-width: $screen-md) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media (min-width: $screen-lg) {
      @content;
    }
  } @else if $breakpoint == 'print' {
    @media print {
      @content;
    }
  }
}
