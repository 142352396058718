// workflow-primary-input styles
@import "../../../styles/variables";

.workflow-primary-field__input-group {
  height: 3.5rem;
  background: #f2f2f2;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  &:focus-within {
    background: #ffffff;
    border: 2px solid #3170c0;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(49, 112, 192, 0.418591);
    border-radius: 2px;
  }
}
.workflow-primary-field--valid {
  .workflow-primary-field__input-group {
    background: #4bde86;
    border: 2px solid #4bde86;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(75, 222, 134, 0.42);
    border-radius: 2px;
    input::placeholder {
      color: black;
    }
  }
  .workflow-primary-field__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:before {
      background: #4bde86;
      border: 2px solid #4bde86;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(75, 222, 134, 0.42);
      border-radius: 2px;
    }
    &:after {
      content: "";
      background: url("./icon-success--white.svg");
      background-size: 100%;
      height: 2rem;
      opacity: 1;
      transform: translateY(0);
      width: 30px;
      z-index: 9;
    }
  }
  .workflow-primary-field__btn__icon {
    display: none;
  }
}

.workflow-primary-field--error {
  .workflow-primary-field__input-group {
    background: $errorBackgroundColor;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(233, 30, 77, 0.42);
    border-radius: 2px;
    color: $errorTextColor;
    input {
      &:focus {
        box-shadow: rgba(233, 30, 77, 0.24);
      }
      &::placeholder {
        color: black;
      }
    }
  }
  .workflow-primary-field__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:before {
      background: $errorBackgroundColor;
    }
    &:after {
      content: "";
      background: url("./icon-error.svg");
      background-size: 100%;
      height: 2rem;
      opacity: 1;
      transform: translateY(0px);
      width: 3rem;
      z-index: 9;
    }
  }
  .workflow-primary-field__btn__icon {
    display: none;
  }
  .workflow-primary-field__errorMsg {
    visibility: visible;
    display: block;
  }
}
.workflow-primary-field__input {
  background: transparent;
  border: none;
  color: inherit;
  font-size: 1.3rem;
  padding: 0.8rem;
  padding-right: 2rem;
  padding-left: 5rem;
  width: 100%;
  &:focus {
    outline: none;
  }
}
.workflow-primary-field__label {
  display: hidden;
}
.workflow-primary-field__btn {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 5rem;
  &:before {
    content: "";
    position: absolute;
    opacity: 0;
    transition: 100ms;
    height: 100%;
    width: 100%;
  }
  &:after {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 100ms, opacity 100ms;
  }
  &:focus {
    outline: none;
  }
}
.workflow-primary-field__errorMsg {
  display: none;
  visibility: hidden;
  position: relative;
  top: calc(100%);
  background: #a73235;
  width: 100%;
  color: white;
  justify-content: center;
  font-weight: 600;
  padding: 0.5rem;
  padding-left: 8rem;
}
.workflow-primary-field__errorLink {
  color: white;
  border: solid 2px white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  margin-left: 1rem;
  transition: 100ms;
  &:hover {
    background: white;
    color: #a73235;
  }
}
