// modal styles
@import "../../../styles/_variables.scss";

.modal {
  left: 0;
  position: fixed;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;

  &:before {
    content: "";
    background: #f2f2f2;
    position: fixed;
    transition: opacity 400ms;
    z-index: -1;

    @media screen and (max-width: 1367px) {
      height: 0.1%;
      width: 0.1%;
      display: flex;
      text-align: center;
      align-content: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1367px) {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;
  }
}

.modal-in {
  align-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  margin: 2rem;
  position: relative;
  top: 10rem;
  bottom: 65rem;
  left: 10rem;
  right: 1rem;
  transform: translateY(50px);
  transition: opacity 200ms 200ms, transform 200ms 200ms;
  width: 585px;

  @media screen and (max-width: 1367px) {
    height: calc(100% - 89rem);
  }
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 1rem;
  top: 0rem;

  img {
    height: 2rem;
    width: 2rem;
  }
}

.modal-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 560px;
  background: white;
  padding: 13px;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: red
} 

.modal-message {
  font-size: 1.5rem;
  & p + p {
    margin-top: 0;
  }
}

.modal-buttons {
  margin-top: 5rem;
  width: 100%;

  *:not(:first-child) {
    margin-top: 1rem;
  }
}

.modal-custom-title {
  display: flex;
  flex-direction: row;
}

.modal-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  zoom: 200%;

  & div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #4a90e2;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: modal-loader1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: modal-loader2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: modal-loader2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: modal-loader3 0.6s infinite;
    }
  }
}

@keyframes modal-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes modal-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes modal-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
