// ex. .padding-left-sm-2x or .margin-top-bottom-4x

@mixin generate-spacing-classes(
  $default-space-values: (
    0,
    1,
    2,
    3,
    4,
    5,
    6
  ),
  $breakpoints: (
    sm small,
    md medium,
    lg large
  )
) {
  @each $space in $default-space-values {
    .padding-#{nth($space,1)}x {
      padding: #{nth($space, 1)}rem !important;
    }
    .padding-left-#{nth($space,1)}x {
      padding-left: #{nth($space, 1)}rem !important;
    }
    .padding-right-#{nth($space,1)}x {
      padding-right: #{nth($space, 1)}rem !important;
    }
    .padding-top-#{nth($space,1)}x {
      padding-top: #{nth($space, 1)}rem !important;
    }
    .padding-bottom-#{nth($space,1)}x {
      padding-bottom: #{nth($space, 1)}rem !important;
    }
    .padding-left-right-#{nth($space,1)}x {
      padding-left: #{nth($space, 1)}rem !important;
      padding-right: #{nth($space, 1)}rem !important;
    }
    .padding-top-bottom-#{nth($space,1)}x {
      padding-top: #{nth($space, 1)}rem !important;
      padding-bottom: #{nth($space, 1)}rem !important;
    }

    .margin-#{nth($space,1)}x {
      margin: #{nth($space, 1)}rem !important;
    }
    .margin-left-#{nth($space,1)}x {
      margin-left: #{nth($space, 1)}rem !important;
    }
    .margin-right-#{nth($space,1)}x {
      margin-right: #{nth($space, 1)}rem !important;
    }
    .margin-top-#{nth($space,1)}x {
      margin-top: #{nth($space, 1)}rem !important;
    }
    .margin-bottom-#{nth($space,1)}x {
      margin-bottom: #{nth($space, 1)}rem !important;
    }
    .margin-left-right-#{nth($space,1)}x {
      margin-left: #{nth($space, 1)}rem !important;
      margin-right: #{nth($space, 1)}rem !important;
    }
    .margin-top-bottom-#{nth($space,1)}x {
      margin-top: #{nth($space, 1)}rem !important;
      margin-bottom: #{nth($space, 1)}rem !important;
    }
  }

  @each $breakpoint in $breakpoints {
    @include respond-to(#{nth($breakpoint, 2)}) {
      @each $space in $default-space-values {
        .padding-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding: #{nth($space, 1)}rem !important;
        }
        .padding-left-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding-left: #{nth($space, 1)}rem !important;
        }
        .padding-right-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding-right: #{nth($space, 1)}rem !important;
        }
        .padding-bottom-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding-bottom: #{nth($space, 1)}rem !important;
        }
        .padding-top-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding-top: #{nth($space, 1)}rem !important;
        }
        .padding-left-right-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding-left: #{nth($space, 1)}rem !important;
          padding-right: #{nth($space, 1)}rem !important;
        }
        .padding-top-bottom-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          padding-top: #{nth($space, 1)}rem !important;
          padding-bottom: #{nth($space, 1)}rem !important;
        }

        .margin-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin: #{nth($space, 1)}rem !important;
        }
        .margin-left-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin-left: #{nth($space, 1)}rem !important;
        }
        .margin-right-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin-right: #{nth($space, 1)}rem !important;
        }
        .margin-bottom-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin-bottom: #{nth($space, 1)}rem !important;
        }
        .margin-top-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin-top: #{nth($space, 1)}rem !important;
        }
        .margin-left-right-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin-left: #{nth($space, 1)}rem !important;
          margin-right: #{nth($space, 1)}rem !important;
        }
        .margin-top-bottom-#{nth($breakpoint,1)}-#{nth($space,1)}x {
          margin-top: #{nth($space, 1)}rem !important;
          padding-bottom: #{nth($space, 1)}rem !important;
        }
      }
    }
  }
}

@include generate-spacing-classes();
