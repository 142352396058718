@import '../../../../styles/_variables.scss';

// Dash table filter

$dash-filter-gap: 0.4rem;

.dash-table-filter__container {
  display: flex;
  margin: $dash-filter-gap;
}

.dash-table_filter__item {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  height: 50px;
  justify-content: center;
  margin-right: $dash-filter-gap;
  min-width: 75px;
  padding: 11px 20px;
}

.dash-table_filter__item-selected {
  background: #4a90e2;
  border-radius: 2px;
  color: #ffffff;
}