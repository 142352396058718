@import "../../../styles/_variables.scss";

.dash-main {
  background: rgb(255, 255, 255);
  height: 88vh;
  order: 2;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  width: calc(100% - 405px);
  @media print {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    height: calc(100% - 4.7rem);
  }
  .ReactTable {
    height: 100% !important;
  }
}

.dash-main-chart {
  @extend .dash-main;
  align-items: center;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.ReactTable .rt-noData {
  font-size: $text-mega;
  margin-top: 68px !important;
  text-align: center;
  width: 100%;
}

.width-match {
  width: 550px;
}

.width-50 {
  width: 50%;
}

.orderID {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.333333px;
  padding: 4px 0px;
  color: #ffffff;
  background: #062247;
  border-radius: 3px;
  display: block;
  height: 29px !important;
  width: 29px !important;
}

.dash-main-bttn-cntnr {
  display: flex;
  justify-content: center;
  padding: 0px 10px 10px 10px;
  box-shadow: 0px -4px 10px rgba(102, 102, 102, 0.25);
  position: relative;
  z-index: 10;
}
