// workflow-primary-input styles
@import "../../../../../styles/variables";

.workflow-primary-field__errorMsg {
  visibility: visible;
  display: block;
}

.reference-number-error {
  color: red($color: #000000);
}

.reference-number {
  width: 100%;
  margin-top: 1.8rem;
}
